<!--弹框组件-->
<template>
    <a-modal v-model:visible="visible" :title="title" :width="700" @confim="handleConfirm">
        <slot></slot>
        <template #footer>
            <a-button key="back" @click="handleCancel">取消</a-button>
            <a-button key="submit" type="primary" @click="handleConfirm">保存</a-button>
        </template>
    </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
    name: 'DialogAssembly',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup(props, ctx) {
        const initData = reactive({
            visible: false
        })
        const handleOpen = () => {
            initData.visible = true;
        };

        const handleCancel = () => {
            initData.visible = false;
            ctx.emit('handleCancle')
        };
        const handleConfirm = () => {
            ctx.emit('handleConfirm')
        };
        return {
            ...toRefs(initData),
            handleConfirm,
            handleOpen,
            handleCancel,
        }
    }
})
</script>
