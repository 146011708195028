<template>
    <a-upload name="file_name" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" :customRequest="uploadImg">
        <slot></slot>
        <div v-if="!img">
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">上传</div>
        </div>
    </a-upload>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, toRefs, getCurrentInstance } from 'vue';

export default defineComponent({
    name: 'UploadImage',
    components: {
        LoadingOutlined,
        PlusOutlined,
    },
    props: {
        file_type: {
            type: String
        },
        img: {
            type: String
        },
        width: {
            type: String,
            default: '100px'
        },
        height: {
            type: String,
            default: '100px'
        }
    },

    setup(props, ctx) {
        const { proxy } = getCurrentInstance();

        const data = reactive({
            loading: false
        });

        const uploadImg = (info) => {
            data.loading = true;
            let file = new FormData();
            file.append('file_name', info.file);
            file.append('file_type', props.file_type);
            proxy.http.post('/upload/upload', file).then((response) => {
                ctx.emit('uploadImage', response.path)
                data.loading = false;
            })
        }

        const beforeUpload = file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                proxy.message.error('仅支持JPG、JPEG、PNG格式！');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                proxy.message.error('图片不得超过2MB!');
            }
            return isJpgOrPng && isLt5M;
        };

        return {
            ...toRefs(data),
            beforeUpload,
            uploadImg
        };
    },
});
</script>
<style lang="less">
.avatar-uploader > .ant-upload {
    width: 100px;
    height: 100px;
    img {
        max-width: 652px;
        height: 100%;
        object-fit: cover;
    }
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
